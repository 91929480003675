<template>
	<div>
    <Header></Header> 
		<div class="reset">
			<form class="reset--form" @submit.prevent="onReset">
				<div class="reset--header">
					<h2 class="reset--title">Reset your password</h2>
				</div>
				<div class="reset--tips">
					<p>To reset your password, enter your email below and submit. An email will be sent to you with instructions about how to complete the process.</p>
				</div>
				<div class="reset--item">
					<label class="reset--label" for="email">Email: </label>
					<input class="reset--input" type="email" name="email" id="email" v-model="user.email" placeholder="Enter your email" @blur="onBlur($event)" required />
				</div>
				<div class="reset--item">
					<button class="reset--button" :class="{isValid: isValid}" :disabled="!isValid" type="submit">Reset Password</button>
				</div>
			</form>
		</div>
		<Main></Main>
		<Footer></Footer> 
	</div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/partials/Header.vue';
import Footer from '@/components/partials/Footer.vue';
import Main from '@/components/partials/Main.vue';
import { mapActions } from 'vuex';
export default {
	name: 'Reset',
	components: {
		Header,
		Footer,
		Main,
	},
	data() {
		return {
			user: {
				email: '' || this.$route.query.email,
			},
			validations: [],
		};
	},
	computed: {
		isValid() {
			return this.user.email !== '';
		},
	},
	methods: {
    ...mapActions(['reset']),
    onBlur(event) {
      event.target.style.borderColor = event.target.value? 'rgba(229,231,235, 1)' : 'rgba(255, 0, 0, 1)';
    },
		validate() {
			// Empty Errors container
			this.validations = []; 
			// Check If Email Is Empty
			if (!this.user.email) {
				this.validations.push("Email is required!");
			}
			// Clear error messages on the page
			setTimeout(() => this.validations = [], 5000);
			// If No Errors Return True
			if (this.validations.length) {
				return false;
			}
			return true;
		},
		async onReset() {
			if (!this.validate()) return;
			try {
				await this.reset(this.user);
				this.$router.push({ name: 'Login' });
			} catch (error) {
				this.setMessage({text: error.message, status: false});
			}
		},
	},
};
</script>

<style scoped>
/* reset */
.reset {
	padding: 1rem;
	width: 100%;
}
.reset--form {
	width: 100%;
	margin: 50px auto;
}
.reset--title {
	text-align: center;
	margin: 2rem 0;
}
.reset--tips {
  line-height: 21px;
  width: auto;
  font-size: 14px;
  letter-spacing: normal;
  margin-bottom: 40px;
}
.reset--item {
	display: flex;
	flex-direction: column;
	border: none;
	margin: 0px 0px 20px;
	padding: 0px;
}
.reset--label {
	font-size: 14px;
	color: rgb(61, 79, 88);
	position: relative;
	height: 16px;
	/*width: 36px;*/
  text-align: left;
	font-weight: bold;
	line-height: 16px;
	letter-spacing: 0.02em;
}
.reset--input {
	background-color: rgb(255, 255, 255);
	border: 1px solid rgba(229,231,235, 1);
	border-radius: 4px;
	height: 52px;
	width: 100%;
	font-size: 16px;
	line-height: 24px;
	margin-top: 5px;
	padding-left: 12px;
	padding-right: 12px;
	transition: border-color 150ms ease-in-out 0s;
}
.reset--button {
	position: relative;
	background-color: transparent;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	-webkit-box-align: stretch;
	height: 45px;
	width: 100%;
	font-size: 18px;
	margin-top: 48px;
	margin-bottom: 5px;
	border-radius: 4px;
	text-decoration: none;
	cursor: not-allowed;
	z-index: 0;
	/* appearance: none; */
	transition: all 150ms ease-in-out 0s;
	box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
}
.reset--button.isValid {
	cursor: pointer;
	background-color: rgba(209,213,219, 1);
}
.reset--button.isValid:hover {
  opacity: .5;
}
.reset--option {
	margin: 15px;
	color: rgb(33, 49, 60);
	font-size: 15px;
	display: inline;
	text-align: start;
	display: block;
	text-align: center;
}
.forget--password {
	display: inline-block;
	text-align: right;
	margin-top: 20px;
	width: 300px;
	width: calc(100vw - 32px);
	width: 100%;
}
.forget--password a {
	text-decoration: none;
	color: rgb(0, 124, 173);
	cursor: pointer;
	font-size: 15px;
}
.auth--link {
	text-decoration: none;
	color: rgb(0, 124, 173);
}

/* mini */
@media only screen and (min-width: 481px) {
.reset {
	width: 410px;
	margin: 0 auto;
}
}
/* max */
@media only screen and (min-width: 981px) {

}
</style>